import { render, staticRenderFns } from "./full-page.vue?vue&type=template&id=e68773ba"
import script from "./full-page.vue?vue&type=script&lang=js"
export * from "./full-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0___d9816230dbea7e104690bfe250ed4381/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports