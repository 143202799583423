export default {
  actions: {
    copyLink: "Copia link",
    linkCopied: "Link copiato negli appunti",
    add: "Aggiungi",
    yes: "Sì",
    no: "No",
    save: "Salva",
    cancel: "Annulla",
    start: "Iniziamo!",
    confirmDelete: "Conferma eliminazione",
    new: {
      f: "Nuova",
      m: "Nuovo",
    },
    edit: "Modifica",
    delete: "Elimina",
    deleteImage: "Elimina immagine",
    goToDocumentation: "Vai alla doc",
    forgotPassword: "Password dimenticata?",
    loading: "Caricamento...",
    details: "Dettagli",
    activate: "Attiva",
    request: "Richiedi",
    viewAll: "Guarda tutti",
    searchDots: "Cerca...",
    activateUser: "Attiva utenza",
    needSupport: "Ho bisogno di supporto",
  },
  attachments: {
    label: {
      short: "All.",
      full: "Allegati"
    }
  },
  global: {
    languages: {
      it: "Italiano",
      en: "Inglese",
      es: "Spagnolo"
    },
    hints: {
      typeFullAddress: "Digita l'indirizzo completo"
    },
    accountData: {
      firstName: "Nome",
      lastName: "Cognome",
      gender: "Genere",
      taxIDCode: "Codice fiscale",
      birthDate: "Data di nascita",
      birthPlace: "Luogo di nascita",
      email: "Email",
      nickname: "Nickname",
      phoneNumber: "Numero di telefono",
      address: "Indirizzo",
      zipCode: "CAP",
      city: "Città",
      province: "Provincia",
      website: "Sito web"
    },
    organizationData: {
      name: "Nome dell'organizzazione",
      logo: "Logo",
      description: {
        label: "Descrizione",
        aboutYourOrganization: "Descrivi la tua organizzazione",
        hint: "Dicci in poche parole perché la tua organizzazione è speciale. Questi dati saranno letti da centinaia di atleti sull'app!"
      },
      promo: {
        label: "Promozioni e codici sconto",
        hint: "Inserisci dei codici sconto o altre promozioni da mostrare agli atleti sull'app"
      },
      communicationLanguage: "Lingua per le comunicazioni",
      headquartersAddress: "Sede legale",
      vatNumber: "Partita IVA",
      defaultCurrency: "Valuta di default"
    },
    genders: {
      male: "Maschio",
      female: "Femmina",
      notSpecified: "N/A"
    }
  },
  components: {
    languageSelect: {
      title: "Scegli la lingua",
    },
    menu: {
      changeLanguage: "Cambia lingua",
      changeOrganization: "Cambia organizzazione",
      logout: "Disconnetti",
      account: "Account",
      logoutConfirmation: "Sei sicuro di voler effettuare il logout?",
      account: {
        label: "Account",
        organization: "Organizzazione",
        plans: "Piani",
        paymentMethods: "Metodi di pagamento",
        accessData: "Dati d'accesso",
      },
      documentation: {
        title: "Documentazione",
        dashboard: "Dashboard di admin",
        mobileApp: "App atleti",
        legal: "Note legali",
      }
    },
    tables: {
      membershipsTable: {
        headers: {
          athlete: "Atleta",
          registrationStatus: "Stato iscrizione",
          phoneNumber: "N. Telefono",
          email: "Email",
          taxIDCode: "Cod. Fisc.",
          availability: "Disponibilità",
          dinkCoin: "Dink coin",
          sessionsStatus: "Stato incontri",
          balance: "Bilancio",
          documents: "Documenti",
          weeklySessions: "Incontri settimanali",
          sites: "Sedi",
          groups: "Gruppi",
          level: "Livello"
        },
        tooltips: {
          accepted: "Accettata",
          pending: "In attesa di conferma",
          rejected: "Rifiutata",
          canceled: "Cancellata",
          okWithPayments: "Situazione pagamenti regolare",
          atLeastOnePaymentIncomplete: "Almeno un pagamento incompleto",
          atLeastOnePaymentDue: "Almeno un pagamento scaduto"
        },
        documents: {
          provided: "documento fornito | documenti forniti",
          expirationDate: "Data di scadenza",
          types: {
            id: "Documento di identità",
            greenPass: "Green Pass",
            medicalCertificate: "Certificato medico",
            generic: "Modulistica",
            federalMembership: "Tesseramento federale"
          }
        },
        weeklySessions: {
          title: "Incontri settimanali",
          timeFrameInfo: "L'atleta {name} è iscritto a questi incontri settimanali nel periodo {timeFrame}",
          noTimeFrameWarning: "Stai visualizzando la situazione di {name} senza aver impostato un filtro temporale",
          table: {
            course: "Corso",
            site: "Sede",
            time: "Orario",
            coverage: "Copertura",
            status: "Stato"
          },
          status: {
            draft: "Bozza",
            published: "Pubblicato"
          }
        },
        itemsPerPage: "Atleti per pagina",
        noData: "Nessun dato da mostrare",
        noResults: "Nessun risultato corrisponde alla tua ricerca"
      },
      trainingSessionsTable: {
        headers: {
          name: "Nome",
          startAt: "Data e ora",
          participants: "Atleti",
          coaches: "Coach",
          siteAndField: "Sede",
          accessRules: "Accesso",
          cancelRules: "Disdette",
          notificationRules: "Recap"
        },
        notifications: {
          coachRecapEnabled: "Recap ai coach entro le {time} del giorno dell'allenamento",
          coachRecapEnabledHours: "Recap ai coach entro {hours} prima dell'inizio dell'allenamento",
          coachRecapDisabled: "Recap disabilitato"
        },
        access: {
          absoluteTime: "Fino alle {time} del giorno dell'allenamento",
          relativeTime: "Fino a {hours} prima dell'inizio dell'allenamento",
          hour: "ora",
          hours: "ore"
        },
        cancel: {
          absoluteTime: "Entro le {time} del giorno dell'allenamento",
          relativeTime: "Entro {hours} prima dell'inizio dell'allenamento",
          hour: "ora",
          hours: "ore"
        },
        coaches: "coach",
        itemsPerPage: "Allenamenti per pagina",
        field: "Campo"
      }
    },
    filters: {
      memberships: {
        purchases: "Acquisti",
        purchasedPackages: "Pacchetti acquistati",
        selectPackages: "Seleziona uno o più pacchetti",
        andOthers: "e altri",
        purchasesFrom: "Acquisti a partire dal",
        purchasesTo: "Acquisti fino al",
        weeklySessions: "Incontri settimanali",
        insertedFrom: "Inserito a partire dal",
        insertedTo: "Inserito fino al",
        noSite: "Nessuna sede",
        noGroup: "Nessun gruppo",
        noLevel: "Nessun livello",
        timeErrors: {
          sameTime: "I due orari non possono essere identici",
          startBeforeEnd: "L'orario di inizio disponibilità deve essere precedente all'orario di fine disponibilità",
          endAfterStart: "L'orario di fine disponibilità deve essere successivo all'orario di inizio disponibilità"
        },
        resetFilters: "Resetta filtri",
        apply: "Applica",
        availability: {
          title: "Disponibilità",
          weekDay: "Giorno della settimana",
          from: "Dalle",
          to: "Alle"
        },
        placeholders: {
          selectSite: "Seleziona la sede",
          selectStatus: "Seleziona stato",
        },
        counters: {
          plusOthers: "+{count}"
        }
      }
    }
  },
  general: {
    app: "App",
    organization: "Società",
    name: "Nome",
    level: "Livello",
    levels: "Livelli",
    visibility: "Visibilità",
    min: "Minimo",
    max: "Massimo",
    course: "Corso",
    site: "Sede",
    group: "Gruppo",
    selectSite: "Seleziona la sede",
    selectField: "Seleziona il campo",
    selectCoaches: "Seleziona allenatori",
    numberAthletes: "Numero atleti",
    selectAthletes: "Seleziona atleti",
    selectedAthletes: "Atleti selezionati",
    selectGender: "Seleziona il genere",
    selectGroup: "Seleziona gruppo",
    selectLevel: "Seleziona il livello",
    inTest: "In prova",
    field: "Campo",
    fieldCountable: "Campo {name}",
    customTemplate: "Personalizzato",
    noData: "Nessun risultato trovato",
    loading: "Caricamento in corso",
    whenItHappens: "Quando si svolge",
    school: "Scuola",
    athlete: "Atleta",
    gender: "Genere",
    filterByGender: "Filtra per genere",
    filterByPayment: "Filtra per pagamento",
    selectState: "Seleziona stato",
    documents: "Documenti",
    payments: "Pagamenti",
    situation: "Situazione",
    registry: "Anagrafica",
    payment: "Pagamento",
    paymentType: "Metodo pagamento",
    balance: "Saldo",
    details: "Dettagli",
    template: "Pacchetto",
    filterByTemplate: "Filtra per pacchetto",
    origin: "Origine",
    status: "Stato",
    registryAthlete: "Anagrafica atleta",
    type: "Tipologia",
    date: "Data",
    amount: "Importo",
    positions: {
      other_m: "{pos}°",
      other_f: "{pos}ª",
      other_n: "{pos}°",
      many_m: "{pos}°",
      many_f: "{pos}ª",
      many_n: "{pos}°",
    },
    trainingSessions: "Allenamenti",
    somethingWentWrong: "Qualcosa è andato storto...",
    week: "Settimana",
    day: "Giorno",
    detail: "Dettaglio",
    modify: "Modifica",
    confirmDeleteTraining: "Sei sicuro di voler cancellare questo allenamento?",
    noTrainingWeek: "Non ci sono allenamenti programmati in questa settimana",
    noTrainingDay: "Non ci sono allenamenti programmati per questo giorno",
    athletes: "Atleti",
    time: "Orario",
    location: "Sede",
    newTraining: "Nuovo Allenamento",
    calendar: "Calendario",
    grid: "Griglia",
    of: "di",
    spotTraining: "Allenamento Spot"
  },
  timeSlots: {
    selectPeriod: "Seleziona periodo",
    timeSlot: "Filtro temporale",
    noFilter: "Nessun filtro",
    stepperTitle: "Seleziona il periodo temporale",
  },
  errors: {
    auth: {
      wrongEmailOrPasswordError:
        "Le credenziali inserite non sono corrette, prova di nuovo",
    },
  },
  payment: {
    deleteConfirm: "Si è sicuri di voler eliminare i pagamenti selezionati? L'operazione non potrà essere annullata",
    additionalData: "Dati aggiuntivi",
    dueDate: "Data prevista",
    paid: "Pagato",
    paidAt: "Data pagamento",
    confirmed: "Confermato",
    confirmedAt: "Data di conferma",
    label: "Pagamento",
    limitedActionsForStripePayments: "Azioni di modifica e cancellazione limitate sui pagamenti Stripe",
    create: {
      title: "Crea pagamento",
    },
    edit: {
      title: "Modifica pagamento",
    },
    noPaymentsAvailableForSubscription: "Nessun pagamento effettuato per questa transazione",
    disclaimers: {
      transactionsWithoutPaymentsBatchUpdate: "Abbiamo notato che hai selezionato transazioni senza pagamenti associati:",
      singleTransactionWithoutPayment: "Transazione del {transactionDate} di {athlete} per {amount} €",
      selectPaymentMethodParagraphs: [
        "Dink creerà un pagamento automatico per permetterti di contrassegnare come saldate queste transazioni.",
        "Seleziona il metodo di pagamento che vuoi che venga usato per registrare questi pagamenti (la selezione avrà effetto su tutti gli elementi selezionati).",
      ]
    },
    specificActions: {
      updatePayments: "Aggiorna pagamenti"
    },
    warnings: {
      membershipSubscription: {
        withStripe: "Alcune delle transazioni selezionate hanno pagamenti Stripe, e pertanto non verranno modificate. Continuare?"
      }
    }
  },
  paymentMethods: {
    genericInfo: "Info generali",
    title: "Metodi di pagamento",
    name: "Metodo di pagamento",
    edit: "Modifica metodo",
    disclaimers: {
      noData: "Non ci sono ancora metodi di pagamento impostati per questa organizzazione",
      manageMethodsFromSpecificPage: "Adesso puoi gestire i tuoi metodi di pagamento direttamente dalla pagina dedicata del tuo account."
    },
    deleteConfirm:
      "Si è sicuri di voler eliminare il metodo di pagamento selezionato?",
    types: {
      cash: "Contanti",
      redsys: "RedSys",
      bankWire: "Bonifico bancario",
      stripe: "Stripe",
    },
    label: "Etichetta",
    bankWire: {
      owner: "Intestatario",
      iban: "IBAN",
    },
    redsys: {
      secretKey: "Chiave segreta",
      merchantCode: "Identificativo commercio",
      terminalNumber: "Numero del terminale",
    },
    specificActions: {
      goToPaymentMethods: "Vai ai metodi di pagamento"
    },
    stripe: {
      accountId: "Account ID",
      confirmed: "Confermato",
      notConfirmed: "Non confermato",
      continue: "Riprendi l'integrazione"
    },
    tutorials: {
      pageTutorials: [
        {
          title: "A che cosa serve questa pagina?",
          text: "Questa è la pagina da cui potrai gestire i tuoi metodi di pagamento da utilizzare a livello globale sulla tua piattaforma.",
        },
        {
          title: "Dove avrò bisogno dei metodi di pagamento?",
          text: "I metodi di pagamento serviranno ad automatizzare il pagamento degli atleti tramite la loro App mobile. Potrai quindi configurare qui i tuoi metodi di pagamento per poi andarli a richiamare al momento della creazione e/o modifica dei tuoi pacchetti.",
        },
        {
          title: "Quanti metodi di pagamento posso creare?",
          text: "Potrai inserire un massimo di un metodo di pagamento per tipologia. Questo significa che potrai avere un massimo di un account bancario su cui ricevere bonifici e un massimo di un account Stripe su cui ricevere pagamenti digitali dall'app atleti",
        },
      ],
      stripeTutorials: [
        {
          title: "Accedere all'area privata Stripe",
          text: "Potrai accedere alla tua area privata di Stripe cliccando su <a href='https://dashboard.stripe.com/dashboard' target='_blank'>questo link</a>",
        },
        {
          title: "Come posso ottenere uno sconto sulle commissioni Stripe?",
          text: "Per ottenere uno sconto sulle commissioni Stripe (dal 1,5% al <b>1,2% di commissioni</b>) ti basterà di <a href='https://support.stripe.com/questions/contact-stripe-support' target='_blank'>contattare il servizio assistenza Stripe</a> e inviare loro lo statuto della tua ASD, dimostrando di essere un'associazione non a scopo di lucro e richiedendo pertanto uno sconto.",
        },
        {
          title: "Come devo comportarmi in caso di rimborso?",
          text: "In caso di richiesta di rimborso da parte di un atleta, la tua organizzazione sarà l'unico responsabile della restituzione della somma di denaro dovuta. Ti basterà accedere alla <a href='https://dashboard.stripe.com/dashboard' target='_blank'>tua dashboard Stripe</a> per gestire i rimborsi delle quote corrisposte.</a>",
        },
        {
          title: "Altri link utili",
          text: "Ti lasciamo dei link utili sulla documentazione legale Stripe. Trovi quindi i <a href='https://stripe.com/it/legal/ssa' target='_blank'>Termini di servizio Stripe</a> e un link all'<a href='https://stripe.com/it/privacy' target='_blank'>Informativa sulla privacy</a>",
        },
      ],
    },
  },
  dinkCoin: {
    selectType: "Seleziona il tipo di Dink Coin",
    selectTitle: "Tipologia Dink Coin",
    includedCoins: "Dink Coin inclusi",
    coinNumber: "Numero Dink Coin",
    recovery: "Recupero",
    types: {
      gold: "Oro",
      silver: "Argento",
      bronze: "Bronzo",
      recovery: "Recupero",
      recoveryVoucher: "Voucher recupero",
      voucher: "Voucher",
      badge: "Badge corsi",
      noCoin: "Nessun gettone",
    },
  },
  date: {
    day: "Giorno",
    hours: "Ore",
    hour: "Orario",
    fromHour: "Dalle ore",
    toHour: "Alle ore",
    fromDay: "Dal giorno",
    toDay: "Al giorno",
    absoluteTimeChoice: "A un orario preciso",
    relativeToSessionTimeChoice: "Prima dell'allenamento",
  },
  // Le chiavi dei generi devono corrispondere all'enum che si invia al server
  gender: {
    male: "Maschio",
    female: "Femmina",
    mix: "Misto",
  },
  documents: {
    // Le chiavi dei document types devono corrispondere all'enum che si riceve dal server
    types: {
      id: {
        label: "Carta d'identità",
        description: "Carta d'identità, passaporto, patente, etc.",
      },  
      greenPass: {
        label: "Green Pass",
        description: "Green pass, etc.",
      },
      medicalCertificate: {
        label: "Certificato medico",
        description: "Certificato medico agonistico, certificato medico diabetico, etc.",
      },
      generic: {
        label: "Modulistica",
        description: "Modulistica generica della società, etc.",
      },
      federalMembership: {
        label: "Tessera federale",
        description: "Tessera federale, etc.",
      },
    },
  },
  memberships: {
    billingStatus: {
      okWithPayments: "Situazione pagamenti regolare",
      atLeastOnePaymentIncomplete: "Almeno un pagamento incompleto",
      atLeastOnePaymentDue: "Almeno un pagamento mancante"
    },
    documentStatus: {
      ok: "Documenti in regola",
      atLeastOneDocumentExpiring: "Almeno un documento in scadenza",
      atLeastOneDocumentExpired: "Almeno un documento mancante oscaduto"
    }
  },
  transactions: {
    paid: "Pagato",
    status: {
      confirmed: "Approvata",
      toConfirm: "Da approvare",
      rejected: "Rifiutata",
    },
    billingStatus: {
      okWithPayments: "Saldato",
      atLeastOnePaymentIncomplete: "Parzialmente saldato",
      atLeastOnePaymentDue: "Insolvente",
    },
    modal: {
      edit: {
        title: "Modifica transazione"
      },
      create: {
        title: "Registra transazione"
      },
      view: {
        title: "Dettagli transazione"
      },
    }
  },
  staff: {
    units: {
      coach: "Allenatori",
      boardMember: "Direttivo",
      manager: "Manager",
    },
  },
  visibility: {
    all: "Community Dink",
    membership: "La tua Academy",
  },
  courses: {
    courseName: "Corso {courseName}",
    status: {
      draft: "Bozza",
      published: "Pubblicato",
    },
    plannedMeet: "{total} Incontro programmato",
    plannedMeetings: "{total} Incontri programmati",
    plannedMeetInSite: "{total} incontri programmati nella sede {site}",
    plannedMeetingsInSite: "{total} incontri programmati nella sede {site}",
    plannedInOtherSite: "{total} programmato in altre sedi",
    plannedInOtherSites: "{total} programmati in altre sedi",
    meetingOfDay: "Incontro del {day}",
    noCourseFound: "Nessun corso trovato",
  },
  session: {
    name: "Nome dell'allenamento",
    part: "Fa parte del corso",
    site: "Sede",
    field: "Field",
    siteAndField: "Sede e campo",
    details: "Dettagli allenamento",
    manageParticipants: "Gestisci gruppo d'allenamento",
    confirmAbsence: "Sei sicuro di voler segnalare l'assenza di {name}?",
    confirmDelete: "Sei sicuro di voler cancellare il corso <b>{name}</b>?",
    deleteMessage:
      "Tutti gli allenamenti futuri verranno cancellati. Per evitare l'eliminazione degli allenamenti, è necessario disassociarli da questo corso.",
    sessionType: "Tipologia allenamento",
    hoursBeforeSession: "prima dell'allenamento",
    setAbsence: "Segna come assente",
    setPresence: "Segna come presente",
    sessionTypeOptions: {
      standard: "Allenamento Standard",
      openDays: "Allenamento Open Day",
    },
    notifyRules: {
      title: "Regole di notifica",
      hint: "Invia una notifica agli allenatori",
      whenTitle: "Quando viene inviato il recap delle presenze",
      hintCheck: "Invia una notifica ad ogni modifica degli allenamenti",
      notifyCoaches: "Notifica agli allenatori",
      sendNotificationForEachChange: "Notifiche per le modifiche",
    },
    cancelRules: {
      title: "Regole di disdetta",
      hint: "Matura un recupero",
      whenTitle: "Limite di segnalazione assenza",
      signBy: "Segnalazione assenza entro",
      recoveryVoucherRequired: "Voucher di recupero richiesto",
      recoveryVoucherNotRequired: "Voucher di recupero non richiesto",
    },
  },
  spotSession: {
    recoveryRules: "Regole di recupero",
    recoveryCancellationRules: "Regole di disdetta",
    matureRecover: "Matura recupero",
    whoCanRecover: "Chi è ammesso al recupero",
    whatHappensOnCancel: "Cosa succedde alla disdetta",
    ifCanceledInTime: "Se l'assenza è segnalata entro",
    recoverableSession: "Ammetti atleti in recupero",
    accessSessionRule: "Regole di accesso",
    subscribeIn: "Iscrizione entro",
    accessMethods: "Metodi di accesso",
    cancellationRulesHours: "{hours} ore prima dell'allenamento",
    byMidnightDayBefore: "Entro le 23:59 del giorno prima dell'allenamento",
    byNoonDayBefore: "Entro le 12:00 del giorno precedente all'allenamento",
    subscribeByRelative: "{hours} ore prima dell'allenamento",
    subscribeByAbsolute: "Alle ore {hours} del giorno dell'allenamento",
    cancelRules: {
      notCancellable: "Non si maturano recuperi",
      notRecoverable: "Non accessibile per recupero",
    },
  },
  events: {
    eventType: "Tipologia evento",
    eventTypes: {
      standard: "Torneo singolo",
      openDays: "Open Day",
    },
    registrations: {
      editRegistration: "Modifica squadra",
      createRegistration: "Crea squadra",
      showAthletes: "Mostra atleti",
      sendToWaitingList: "Sposta in lista d'attesa",
      sendToEntryList: "Sposta negli iscritti al torneo",
    },
  },
  catalogue: {
    itemType: "Tipologia pacchetto",
    itemTypes: {
      sessions: "Pacchetto allenamenti",
      course: "Abbonamento corso",
    },
    origin: {
      inApp: "Acquisto in app",
      school: "Inserimento scuola",
    },
    visibility: {
      all: "Tutte le visibilità",
      hidden: "Nascosto",
      public: "Tutti gli atleti su Dink",
      siteRestricted: "Limitato ad alcune sedi",
    },
  },
  sites: {
    visibility: {
      public: "Tutti gli atleti su dink",
      membership: "Gli atleti della mia scuola",
      private: "Soltanto gli atleti associati",
    },
  },
  warnings: {
    warningLabel: "Attenzione!",
    waitUp: "Aspetta!"
  },
  weekdaysShort: {
    mon: "Lun",
    tue: "Mar",
    wed: "Mer",
    thu: "Gio",
    fri: "Ven",
    sat: "Sab",
    sun: "Dom",
  },
  weekdaysLong: {
    monday: "Lunedì",
    tuesday: "Martedì",
    wednesday: "Mercoledì",
    thursday: "Giovedì",
    friday: "Venerdì",
    saturday: "Sabato",
    sunday: "Domenica"
  },
  weekdaysLongShortOutput: {
    monday: "Lun",
    tuesday: "Mar",
    wednesday: "Mer",
    thursday: "Gio",
    friday: "Ven",
    saturday: "Sab",
    sunday: "Dom",
  },
  plannerModal: {
    title: "Benvenuto nel nuovo planner corsi!",
    message: "Prima di iniziare abbiamo bisogno di filtrare i tuoi risultati.",
  },
  registrationErrors: {
    generic_create: "Non è stato possibile creare la registrazione",
    generic_edit: "Non è stato possibile modificare la registrazione",
    teamRulesNotRespected: "Non sono stati rispettati i limiti impostati per le squadre",
  },
  entities: {
    login: {
      email: "Email",
      password: "Password",
      currentPassword: "Password attuale",
      newPassword: "Nuova password",
      selectAccount: {
        title: "Seleziona account",
        subtitle: "Seleziona l'organizzazione per cui vuoi agire",
        rolesInOrganization: "Ruoli nell'organizzazione:"
      },
      specificActions: {
        login: "Accedi",
        resetPassword: "Reimposta password",
        confirmYourEmail: "Conferma la tua email",
        insertOTP: "Inserisci codice OTP",
        selectNewPassword: "Scegli una nuova password",
      }
    },
    timeFrames: {
      noData: {
        message: "Non hai ancora creato nessun blocco temporale",
        cta: "Crea il tuo primo blocco"
      },
      name: "Nome del blocco temporale",
      startAt: 'Inizio',
      endAt: 'Fine',
      specificActions: {
        add: "Crea blocco",
        edit: "Modifica blocco temporale"
      }
    },
    team: {
      localizeGender: "f"
    },
    legal: {
      privacyPolicy: "Informativa sulla privacy",
      termsAndConditions: "Termini e Condizioni",
      cookiePolicy: "Cookie Policy",
    },
    staffMembers: {
      roles: {
        manager: "Manager",
        boardMember: "Membro del direttivo",
        coach: "Coach",
      }
    }
  },
  pricing: {
    startingFrom: "A partire da",
    lastRenewal: "Ultimo rinnovo",
    expiryDate: "Data di scadenza",
    yourPlan: "Il tuo piano",
    extendPlan: "Estendi piano",
    activateModule: "Attiva il modulo",
  },
  validationRules: {
    required: "Questo campo è obbligatorio",
    invalidField: 'Il campo non è valido',
    fileSmallerThan: "Il file caricato deve essere di dimensioni inferiori a {size}",
    maxCharText: "Il testo inserito non può superare i {maxChar} caratteri",
    newPasswordMustDiffer: "La nuova password deve essere diversa da quella attuale",
    passwordRegex: "La password deve essere composta da almeno 6 caratteri di cui almeno una maiuscola, una minuscola e un numero",
    emailRegex: "L'indirizzo email non è valido",
    maxCharText: "Il testo inserito non può superare i {maxChar} caratteri",
    minCharText: "Inserire almeno {minChar} caratteri",
    atLeastOneElement: "Selezionare almeno un valore",
  },
  pages: {
    profile: {
      menu: {
        labels: {
          organization: "Organizzazione",
          plans: "Piani",
          paymentMethods: "Metodi di pagamento",
          access: "Dati d'accesso"
        }
      },
      disclaimers: {
        userAccessDisclaimer: "Stai agendo in veste di {role} per conto di {organization}",
        whatUseIsThisData: {
          title: "A che ci servono questi dati?",
          body: "Questi dati servono alla tua organizzazione per poter essere raggiunta e contattata dagli atleti presenti nella nostra applicazione!"
        }
      },
      sections: {
        yourAccount: "Il tuo account",
        accountData: "Dati dell'account",
        organizationData: "Dati dell'organizzazione",
        myOrganization: "La mia organizzazione",
        fiscalData: "Dati fiscali",
        changePassword: "Modifica la tua password",
        plans: "Moduli Dink"
      },
      title: "Account",
      accountData: "Dati anagrafici",
      contact: "Account e contatti",
      position: "Residenza e posizione",
      plans: {
        purchasedTournaments: "Tornei acquistati",
        consumedTournaments: "Tornei consumati",
        remainingTournaments: "Tornei rimanenti",
        noCreditAvailable: "Nessun credito disponibile",
        activeForRemainingDays: "Attivo per {days} giorni",
        expiringInDays: "Scade tra {days} giorni",
        expiredDaysAgo: "Scaduto da {days} giorni",
        expiredToday: "Scade oggi",
        activeStaffMembers: "Membri attivi",
        paidStaffMembers: "Membri pagati",
        lastRenewal: "Ultimo rinnovo",
        expirationDate: "Data di scadenza",
      }
    },
    timeFrames: {
      title: "Blocchi temporali"
    },
    feedback: {
      title: "Segui gli sviluppi",
    },
    legalNotes: {
      title: "Note legali",
      links: {
        privacyPolicy: {
          url: "https://www.iubenda.com/privacy-policy/16070715",
          text: "Informativa sulla privacy",
        },
        termsAndConditions: {
          url: "https://www.iubenda.com/termini-e-condizioni/16070715",
          text: "Termini e Condizioni",
        },
        cookiePolicy: {
          url: "https://www.iubenda.com/privacy-policy/16070715/cookie-policy",
          text: "Cookie Policy",
        },
        dinkUserLicence: {
          text: "Licenza d'uso Dink",
          disclaimers: {
            contactDinkAdmins: "Contatta l'ufficio amministrazione Dink per far registrare la licenza d'uso firmata dalla tua organizzazione e renderla accessibile da questa pagina"
          }
        }
      }
    },
    login: {
      title: "Entra in Dink",
      subtitle: "Effettua il login per iniziare a organizzare le tue attività della tua organizzazione sportiva"
    },
    recoverPassword: {
      title: "Recupera password",
      subtitle: "Inserisci la tua email per recuperare la tua password",
      mailSent: "Abbiamo inviato una mail all'indirizzo {email} con un codice che ci permetterà di validare il tuo indirizzo email.",
      backToLogin: "Torna al login",
      problemsWithOTP: "Problemi con OTP"
    },
    dashboard: {
      title: "Dashboard",
      athleteDistribution: "Distribuzione atleti",
      athleteEnrollment: "Iscrizione atleti",
      genderDistribution: "Come sono distribuiti per genere gli iscritti alla tua scuola",
      monthlyEnrollments: "Iscrizioni mensili degli atleti alla tua scuola",
    },
    staff: {
      pageTitle: "Il mio staff",
      needHelp: "Ho bisogno di aiuto",
      reorderStaff: "Riordina staff",
      add: "Aggiungi",
      onlyManagerCanAdd: "Soltanto il manager dell'organizzazione può aggiungere membri dello staff",
      labels: {
        sector: "Settore",
        role: "Ruolo",
        visibility: "Visibilità",
        access: "Accesso",
        profileImage: "Immagine del profilo",
        resume: "Esperienza",
        name: "Nome",
        lastName: "Cognome",
        email: "Email",
        roleLabel: "Etichetta ruolo",
        
      },
      actions: {
        linkAccount: "Collega utenza",
        viewDetails: "Dettaglio",
        edit: "Modifica",
        delete: "Cancella",
        add: "Crea membro",
      },
      noCurriculum: "Nessun curriculum disponibile",
      deleteCoachConfirmation: "{name} potrebbe essere associato a uno o più allenamenti. Sei sicuro di proseguire?",
      hasAccess: "App e Gestionale",
      noAccess: "Nessun accesso",
      reorderStaff: {
        title: "Riordina staff",
        hint: "Trascina le schede sottostanti per riordinare i membri dello staff",
      },
      grantAccessRequest: "Ciao caro, vorrei che tu mi attivassi l'utenza per il membro dello staff *{name}*. È possibile?",
      noCoachesFound: "Nessun allenatore corrisponde alla tua ricerca",
      noBoardMembersFound: "Nessun membro del direttivo corrisponde alla tua ricerca",
      helpModal: {
        title: "Gestione dello staff",
        body: "<p class='text-body-2'>Inserisci in questa sezione tutti i membri che rendono speciale e unica la tua organizzazione. Ci sono diversi modi in cui puoi inserire il tuo staff nella piattaforma Dink. Vediamoli di seguito.</p>" +
        "<h6 class='text-overline'>Aggiungere un membro senza utenza</h6>" +
        "<p class='text-body-2'>" +
        "Dink ti dà la possibilità di inserire infiniti membri dello staff senza dare loro un accesso alla piattaforma. In questo modo, puoi tenere traccia internamente (come amministratore) di tutti i membri che compongono il tuo staff. L'unico " +
        "<strong>limite</strong> è che questi membri " +
        "<strong>non potranno accedere alla piattaforma</strong> e quindi " +
        "<strong>alleggerirti il lavoro</strong>." +
        "</p>" +
        "<h6 class='text-overline'>Collegare un'utenza allo staff</h6>" +
        "<p class='text-body-2'>" +
        "Questo è il modo sicuramente più " +
        "<strong>smart</strong> per gestire il tuo staff. Collegando il tuo membro dello staff a un'utenza Dink, farai in modo che questo possa " +
        "<b>agire all'interno della piattaforma</b> in piena autonomia e avere una " +
        "<b>visibilità limitata</b> di tutti i dati della tua organizzazione sulla base del ruolo che gli assegnerai." +
        "</p>" +
        "<h6 class='text-overline'>Come posso collegare un'utenza a un membro del mio staff?</h6>" +
        "<p class='text-body-2'>" +
        "Potrai collegare un'utenza Dink al membro del tuo staff semplicemente contattando gli amministratori Dink e richiedendo il collegamento dell'utenza per tutti quegli allenatori o membri del direttivo che vuoi che accedano ai dati della piattaforma." +
        "</p>"
      },
      noData: {
        message: "Nessun membro dello staff da mostrare",
        cta: "Crea il tuo primo membro"
      },
      access: {
        app: "Accesso coach da App",
        dashboard: "Accesso coach da Gestionale",
        label: "Accesso",
      },
      noResultsText: "Nessun risultato corrisponde alla tua ricerca",
      membersPerPage: "Membri per pagina",
      name: "Nome",
      email: "Email",
      sector: "Settore",
      role: "Ruolo",
      visibility: "Visibilità",
      staffMemberInfoWarning: "Alcune informazioni del membro dello staff (nome, cognome, email) saranno modificabili soltanto dal membro dello staff in qualità di titolare del suo account.",
      staffMemberAccessDisclaimer: "<p class='text-body-2'>Da oggi, i tuoi membri dello staff potranno accedere in autonomia su Dink. Gli allenatori hanno poteri limitati, mentre i membri del direttivo avranno pieni poteri.</p><p class='text-body-2'>Per attivare l'utenza ai membri del tuo staff puoi contattare il nostro team.</p>",
      profileImageDisclaimer: "Ti consigliamo di inserire un'immagine del profilo per ogni membro del tuo staff per offrire un'esperienza migliore ai tuoi atleti e ai visitatori del tuo sito web collegato a Dink",
    },
    documentation: {
      title: "Documentazione",
      dashboard: {
        title: "Dashboard",
      },
      mobileApp: {
        title: "App mobile",
      },
    },
    legal: {
      title: "Note legali",
    },
    academy: {
      pageTitle: "Academy",
      registrations: {
        title: "Iscrizioni",
        requiredDocuments: {
          title: "Documenti",
          subheader: "Seleziona i documenti richiesti per le iscrizioni alla tua organizzazione",
          noDocuments: "Nessun tipo di documento selezionato",
        },
        membershipAcceptance: {
          title: "Modalità di iscrizione",
          subheader: "Seleziona la modalità con la quale gli atleti si iscrivono alla tua organizzazione",
          manualApproval: "Approva le iscrizioni manualmente",
          manualApprovalTooltip: "Settando <b>l'accettazione manuale delle iscrizioni</b>, quando gli atleti si iscriveranno alla tua organizzazione <b>dovrai accettare manualmente gli atleti</b> nella tua organizzazione. Potrai farlo da questa pagina, nella sezione \"Richieste di iscrizione\"",
          automaticApprovalTooltip: "Settando <b>l'accettazione automatica delle iscrizioni</b>, quando gli atleti si iscriveranno alla tua organizzazione, <b>entreranno direttamente nella tua rosa atleti</b>. Riceverai comunque una mail di notifica e sarai libero di eliminare gli atleti in qualsiasi momento",
        },
        membershipAvailability: {
          title: "Disponibilità iscrizione",
          subheader: "Richiedi agli atleti di fornirti le loro preferenze di orario settimanale. Questo potrà esserti utile nel momento in cui andrai a comporti i gruppi di allenamento per \"incastrare\" i vari orari",
        },
        list: {
          title: 'Richieste di iscrizione',
          viewSelector: 'Visualizza iscrizioni',
          multipleActions: 'Azioni multiple',
          acceptRequests: 'Accetta richieste',
          markAsPending: 'Segna come in sospeso',
          rejectRequests: 'Rifiuta richieste',
          search: 'Cerca...',
          filter: 'Filtra',
          columns: 'Colonne',
          noRequests: 'Non ci sono richeste di iscrizione da gestire',
          noResults: 'Nessun atleta corrisponde ai tuoi filtraggi',
          athleteDetails: 'Dettagli atleta',
          athletePage: 'Pagina dell\'atleta',
          statusOptions: {
            pending: 'Richieste da accettare',
            canceled: 'Richieste cancellate',
            rejected: 'Richieste rifiutate'
          }
        }
      },
      courses: {
        title: "Scuola",
        training: {
          title: "Agenda allenamenti",
        },
        courses: {
          title: "Corsi",
        },
      },
      administration: {
        title: "Amministrazione",
        athletes: {
          title: "Atleti",
        },
        transactions: {
          title: "Transazioni",
        },
        levels: {
          title: "Livelli",
        },
        groups: {
          title: "Gruppi",
        },
      },
      priceList: {
        title: "Listino prezzi",
        services: {
          title: "Pacchetti",
        },
        promotions: { 
          title: "Promozioni",
        },
      },
      sites: {
        title: "Sedi",
      },
      analytics: {
        title: "Statistiche",
      },
    },
    events: {
      title: "Eventi",
      myEvents: {
        title: "I miei eventi",
      },
      championships: {
        title: "Campionati",
      },
      analytics: {
        title: "Statistiche",
      },
      simulators: {
        title: "Simulatori",
        tournament: {
          title: "Simulatore torneo",
        },
        revenue: {
          title: "Simulatore economico",
        },
      },
    },
    facilities: {
      title: "Strutture",
      myStructures: {
        title: "Le mie strutture",
      },
      reservations: {
        title: "Prenotazioni",
        agenda: {
          title: "Agenda prenotazioni",
        },
        list: {
          title: "Lista prenotazioni",
        },
      },
    },
    settings: {
      title: "Impostazioni",
      timeFrames: {
        title: "Blocchi temporali",
      },
    },
    feedback: {
      title: "Feedback",
    },
    
  },
  currencies: {
    usd: {
      code: "usd",
      label: "Dollaro americano",
      symbol: "$",
    },
    eur: {
      code: "eur",
      label: "Euro",
      symbol: "€",
    },
    chf: {
      code: "chf",
      label: "Franco Svizzero",
      symbol: "CHF",
    }
  },
  widgets: {
    eventsMonthIncome: {
      title: "Incasso tornei di {month}"
    },
    membershipsMonthIncome: {
      title: "Incasso scuola di {month}"
    },
    averageEventsIncome: {
      title: "Incasso medio per torneo",
      subheader: "Media mensile degli incassi provenienti dai tuoi tornei"
    },
    latestMemberships: {
      title: "Ultimi iscritti",
      addAthletes: "Aggiungi atleti",
      addAthletesDescription: "Non hai ancora atleti iscritti alla tua scuola. Puoi aggiungerli manualmente o importare i dati da un file CSV.",
      noAthletes: "Non hai ancora atleti iscritti alla tua scuola."
    },
  },
  time: {
    at: "Alle",
    hours: "ore",
    minutes: "minuti",
    seconds: "secondi",
    days: "giorni",
    weeks: "settimane",
    months: "mesi",
    years: "anni",
    ago: "fa",
    fromNow: "da ora",
    justNow: "adesso",
    yesterday: "ieri",
    tomorrow: "domani",
    from: "da",
    to: "a",
    on: "il",
    at: "alle",
    by: "entro",
    of: "di",
  },
  genders: {
    male: "Maschio",
    female: "Femmina",
    notSpecified: "N/A"
  },
  visibilities: {
    allAthletes: "Tutti gli atleti",
    onlyMyAthletes: "Solo i miei atleti",
    hideFromAthletes: "Nascosta",
  }
};